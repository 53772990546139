import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import docStatus, { DocumentStatus, DocumentStatusValues } from '@/shared/model/documentStatus';
import { namespace } from 'vuex-class';
import { OrganisationDocumentExtraStatusSetting } from '@/shared/model/organisationDocumentExtraStatusSetting';

const logger = new Logger('edit-document-status-dialog');

const organizationModule = namespace('organization');
const documentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');

@Component({
  name: 'edit-document-status-dialog',
  components: {},
})
export default class EditDocumentStatusDialog extends Vue {
  @organizationModule.Getter('getOrganization')
  private organization!: any;

  @documentExtraStatusSettingModule.Getter('getOrganisationDocumentExtraStatusSettings')
  private getOrganisationDocumentExtraStatusSettings!: any;

  get documentExtraStatuses() {
    let result = this.getOrganisationDocumentExtraStatusSettings.items.map(
      (item: OrganisationDocumentExtraStatusSetting) => ({
        id: item.id,
        name: item.name,
        statusValue: item.statusValue,
      })
    );
    result.unshift({
      id: '0',
      name: this.$i18n.t('hasgobd'),
      statusValue: DocumentStatusValues.Hasgobd,
    });
    result.unshift({
      id: '1',
      name: this.$i18n.t('allocated'),
      statusValue: DocumentStatusValues.Allocated,
    });
    result = result.filter((x: any) => x.statusValue !== this.value.statusValue.toLowerCase());
    return result;
  }

  private model!: any;

  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => docStatus.parse({}) })
  private value!: any;

  private onClose() {
    this.$emit('click:close', this.value);
  }

  private async onConfirm() {
    this.value.condition = this.value.conditionArray.toString();
    this.$emit('click:update', this.value);
  }
}
