import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import regexSplitter, { RegexSplitter } from '@/shared/model/regexSplitter';
import DocumentNameparserDetailEdit from './document-nameparser-detail-edit/document-nameparser-detail-edit.vue';
import regexSplitterPart, { RegexSplitterPart } from '@/shared/model/regexSplitterPart';
import regexSplitterPatternPart from '@/shared/model/regexSplitterPatternPart';
import GuidUtils from '@/shared/utils/GuidUtils';
import { User } from 'oidc-client';

const name = 'document-nameparser-edit';
const logger = new Logger(name);

const regexSplitterModule = namespace('regexSplitter');
const orgDocumentPropertyModule = namespace('organisationDocumentProperty');
const authModule = namespace('auth');

@Component({
  name: 'document-nameparser-edit',
  components: { DocumentNameparserDetailEdit },
})
export default class DocumentNameparserEdit extends Vue {
  @regexSplitterModule.Action('getRegexSplitters')
  private actionGetRegexSplitters?: any;
  @regexSplitterModule.Action('deleteRegexSplitter')
  private actionDeleteRegexSplitter?: any;

  @orgDocumentPropertyModule.Action('getOrganisationDocumentPropertys')
  private actionGetOrganisationDocumentPropertys!: any;

  @authModule.Getter('getAccount')
  private account!: User;

  @Ref('document-nameparser-edit-form')
  private refEditForm!: any;

  @Prop({ default: false })
  public isLoading!: boolean;

  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => regexSplitter.parse({}) })
  private value!: RegexSplitter;

  @Watch('value')
  onUpdateValue(newV: RegexSplitter, oldV: RegexSplitter) {
    this.editedItem = Object.assign(this.editedItem, newV);
    this.resetValidation();
  }

  private editedItem: RegexSplitter = regexSplitter.parse({});

  get getPrioritats() {
    return [
      { id: 1, name: '1' },
      { id: 2, name: '2' },
    ];
  }

  //#region rules
  private isFormValid = true;

  get rules() {
    return {
      required: (value: any, field: any) => !!value || this.$t('messages.required', { 0: field }),
      maxlength: (value: any, limit: any) => value.length <= 255 || this.$t('messages.maxlength', { 0: limit }),
      min: (v: any) => v.length >= 3 || this.$t('messages.min'),
    };
  }
  //#endregion

  async created() {
    this.actionGetOrganisationDocumentPropertys();
  }

  resetValidation() {
    if (this.refEditForm) this.refEditForm.resetValidation();
  }

  onClickClose() {
    this.resetValidation();
    this.$emit('click:close');
  }

  async onClickUpdate() {
    const isFormValidResult = await this.refEditForm.validate();

    if (isFormValidResult) {
      this.$emit('click:update', this.editedItem);
    }
  }

  async onClickDelete(account: RegexSplitter) {
    this.$confirm
      .open(
        `${this.$t('delete_dialog_title')}`,
        `${this.$t('delete_dialog_text', {
          0: account.name,
        })}`,
        {
          cancelText: `${this.$t('cancel')}`,
          okText: this.$t('delete'),
        }
      )
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteRegexSplitter(account.id)
            .then(() => {
              this.actionGetRegexSplitters();
              this.onClickClose();
            })
            .catch((err: any) => {
              logger.error(err);
            });
        }
      });
  }

  private model: RegexSplitterPart[] = [];
  onClickAddNameparserPart() {
    const item = regexSplitterPart.parse({
      id: GuidUtils.newGuid(),
      targetModel: '',
      targetSortOrder: 1,
      organisationId: this.account.profile.current_organization_id,
      regexSplittersId: this.editedItem.id,
    });

    const subItem = regexSplitterPatternPart.parse({
      id: GuidUtils.newGuid(),
      type: 'text',
      regexSplitterPartsId: item.id,
      organisationId: this.account.profile.current_organization_id,
    });

    item.regexSplitterPatternParts.push(subItem);

    this.editedItem.regexSplitterParts.push(item);
  }

  itemEditDialogOnAddpattern(item: RegexSplitterPart) {
    const subItem = regexSplitterPatternPart.parse({
      id: GuidUtils.newGuid(),
      type: 'text',
      regexSplitterPartsId: item.id,
      organisationId: this.account.profile.current_organization_id,
    });
    item.regexSplitterPatternParts.push(subItem);
  }

  itemEditDialogOnDeletepattern(item: RegexSplitterPart, itemId: string) {
    this.editedItem.regexSplitterParts.splice(
      this.editedItem.regexSplitterParts.findIndex((v) => v.id === itemId),
      1
    );
  }

  itemEditDialogOnDeletepatternPart(item: RegexSplitterPart, subItemId: string) {
    item.regexSplitterPatternParts.splice(
      item.regexSplitterPatternParts.findIndex((v) => v.id === subItemId),
      1
    );
  }
}
