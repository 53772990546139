import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import regexSplitter, { RegexSplitter } from '@/shared/model/regexSplitter';
import regexSplitterPart, { RegexSplitterPart } from '@/shared/model/regexSplitterPart';
import { OdataItems } from '@/shared/model/OdataItems';
import { OrganisationDocumentProperty } from '@/shared/model/organisationDocumentProperty';
import { RegexSplitterPatternPart } from '@/shared/model/regexSplitterPatternPart';

const orgDocumentPropertyModule = namespace('organisationDocumentProperty');
const name = 'document-nameparser-detail-edit';
const logger = new Logger(name);

const regexSplitterModule = namespace('regexSplitter');
@Component({
  name: 'document-nameparser-detail-edit',
  components: {},
})
export default class DocumentNameparserDetailEdit extends Vue {
  @regexSplitterModule.Action('getRegexSplitters')
  private actionGetRegexSplitters?: any;
  @regexSplitterModule.Action('deleteRegexSplitter')
  private actionDeleteRegexSplitter?: any;

  @orgDocumentPropertyModule.Getter('getOrganisationDocumentPropertys')
  private getOrganisationDocumentPropertys!: OdataItems<OrganisationDocumentProperty>;

  @Ref('document-nameparser-detail-edit-form')
  private refEditForm!: any;

  @Prop({ default: false })
  public isLoading!: boolean;

  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => regexSplitter.parse({}) })
  private value!: RegexSplitterPart;

  @Watch('value')
  onUpdateValue(newV: RegexSplitterPart, oldV: RegexSplitterPart) {
    this.editedItem = Object.assign(this.editedItem, newV);
    this.resetValidation();
  }

  private editedItem: RegexSplitterPart = regexSplitterPart.parse({});

  get getTargets() {
    return [
      { id: '', name: this.$t('no_links') },
      { id: 'DocumentProperties', name: this.$t('info_fields') },
    ];
  }

  get getTypes() {
    return [
      { id: 'text', name: this.$t('types.text') },
      { id: 'numeric', name: this.$t('types.numeric') },
      { id: 'alphabetic', name: this.$t('types.alphabetic') },
      { id: 'alphanumeric', name: this.$t('types.alphanumeric') },
      { id: 'custom', name: this.$t('types.custom') },
      { id: 'regex', name: this.$t('types.regex') },
    ];
  }

  //#region rules
  private isFormValid = true;

  get rules() {
    return {
      required: (value: any, field: any) => !!value || this.$t('messages.required', { 0: field }),
      maxlength: (value: any, limit: any) => value.length <= 255 || this.$t('messages.maxlength', { 0: limit }),
      min: (v: any) => v.length >= 3 || this.$t('messages.min'),
    };
  }
  //#endregion

  async created() {}

  mounted() {
    this.editedItem = this.value;
  }

  resetValidation() {
    if (this.refEditForm) this.refEditForm.resetValidation();
  }

  onClickClose() {
    this.resetValidation();
    this.$emit('click:close');
  }

  async onClickUpdate() {
    const isFormValidResult = await this.refEditForm.validate();

    if (isFormValidResult) {
      this.$emit('click:update', this.editedItem);
    }
  }

  async onClickDelete(itemId: string) {
    this.$emit('click:deletepattern', this.editedItem, itemId);
  }

  onClickAddNameparserPatternPart() {
    this.$emit('click:addpattern', this.editedItem);
  }

  onClickDeletePatternPart(subItemId: string) {
    this.$emit('click:deletepatternpart', this.editedItem, subItemId);
  }
}
