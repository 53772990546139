import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import docStatus, { DocumentStatus } from '@/shared/model/documentStatus';
import { namespace } from 'vuex-class';
import regexSplitter, { RegexSplitter } from '@/shared/model/regexSplitter';
import DocumentNameparserEdit from './document-nameparser-edit/document-nameparser-edit.vue';
import { debounceAsync } from '@/shared/utils/generalUtils';
import GuidUtils from '@/shared/utils/GuidUtils';
import { RegexSplitterPart } from '@/shared/model/regexSplitterPart';
import { RegexSplitterPatternPart } from '@/shared/model/regexSplitterPatternPart';

const logger = new Logger('document-nameparser');

const organizationModule = namespace('organization');
const regexSplitterModule = namespace('regexSplitter');

@Component({
  name: 'document-nameparser',
  components: { DocumentNameparserEdit },
})
export default class DocumentNameparser extends Vue {
  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => regexSplitter.parse({}) })
  private value!: any;

  @regexSplitterModule.Getter('getRegexSplitters')
  private getRegexSplitters?: any;
  @regexSplitterModule.Action('getRegexSplitters')
  private actionGetRegexSplitters?: any;
  @regexSplitterModule.Action('deleteRegexSplitter')
  private actionDeleteRegexSplitter?: any;
  @regexSplitterModule.Action('updateRegexSplitter')
  private actionUpdateRegexSplitter?: any;

  // debounce
  private getRegexSplittersDebounced = debounceAsync(this.GetRegexSplitters);
  private async GetRegexSplitters() {
    await this.actionGetRegexSplitters();
  }
  // debounce

  @Watch('getRegexSplitters.searchParams', { deep: true })
  onUpdateSearchParams(nwal: any, owal: any) {
    this.getRegexSplittersDebounced({ searchParams: this.getRegexSplitters.searchParams });
  }

  private itemsPerPageOptions: number[] = [10, 25, 50, 100];
  private isSaveBtnLoading = false;

  get regexSplitters() {
    return this.getRegexSplitters;
  }

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('active'), value: 'active' },
      { text: this.$t('name'), value: 'name' },
      { text: this.$t('pattern'), value: 'targetSeparator' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
      },
    ];
    return headers;
  }

  async mounted() {
    const promiseAll = [this.getRegexSplittersDebounced()];
    await Promise.all(promiseAll);
  }

  onClickDelete(item: RegexSplitter) {
    this.$confirm
      .open(
        `${this.$t('delete_dialog_title')}`,
        `${this.$t('delete_dialog_text', {
          0: item.id,
        })}`,
        {
          cancelText: `${this.$t('cancel')}`,
          okText: this.$t('delete'),
        }
      )
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteRegexSplitter(item.id)
            .then(() => {
              this.getRegexSplittersDebounced();
            })
            .catch((err: any) => {});
        }
      });
  }

  onClickCopy(item: RegexSplitter): void {
    const clone = JSON.parse(JSON.stringify(item));
    clone.id = '';
    clone.active = true;
    clone.name = this.$t('copy_of') + ' ' + clone.name;
    clone.regexSplitterParts.forEach((itempart: RegexSplitterPart) => {
      itempart.id = GuidUtils.newGuid();
      itempart.regexSplitterPatternParts.forEach((subItem: RegexSplitterPatternPart) => {
        subItem.id = GuidUtils.newGuid();
        subItem.regexSplitterPartsId = itempart.id;
      });
    });
    this.itemEditDialog.model = { ...regexSplitter.parse(clone) };
    this.itemEditDialog.show = true;
  }

  onClickAdd() {
    this.itemEditDialog.model = { ...regexSplitter.parse({ targetSeparator: '_', active: true }) };
    this.itemEditDialog.show = true;
  }

  async onClickEditt(item: RegexSplitter) {
    //const clone = structuredClone(item);
    const clone = JSON.parse(JSON.stringify(item));
    this.itemEditDialog.model = { ...regexSplitter.parse(clone) };
    this.itemEditDialog.show = true;
  }

  itemEditDialog = {
    show: false,
    model: { ...regexSplitter.parse({}) },
  };

  async itemEditDialogOnClose(item: RegexSplitter) {
    this.itemEditDialog.show = false;
  }

  async itemEditDialogOnUpdate(item: RegexSplitter) {
    this.isSaveBtnLoading = true;
    this.actionUpdateRegexSplitter(item)
      .then((result: any) => {
        this.getRegexSplittersDebounced();
      })
      .catch((err: any) => {})
      .finally(() => {
        this.itemEditDialog.show = false;
        this.isSaveBtnLoading = false;
      });
  }
}
