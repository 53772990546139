import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import orgDocProperty, { OrganisationDocumentProperty } from '@/shared/model/organisationDocumentProperty';
import store from '@/shared/store';

const logger = new Logger('edit-document-property-dialog');

@Component({
  name: 'edit-document-property-dialog',
  components: {},
})
export default class EditDocumentStatusDialog extends Vue {
  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => orgDocProperty.parse({}) })
  private value!: any;

  get isEditMode() {
    return !!this.value.id;
  }

  get title() {
    return this.isEditMode ? this.$i18n.t('title_edit') : this.$i18n.t('title_add'); // i18n.tc(`title_edit`) is not working here, just shows `title_edit` keyS
  }

  private onClose() {
    this.$emit('click:close', this.value);
  }

  private async onConfirm() {
    this.$emit('click:update', this.value, true);
  }

  get getLink() {
    return `${process.env.VUE_APP_BASE_PATH_REDIRECT}/dashboard/organisation/609e332fd22180768f0e6ac2/property/609e3331d22180768f0e6ac4/search/%term%`;
  }

  copLinkToClipboard() {
    navigator.clipboard.writeText(this.getLink).then(() => {
      store.commit(
        'setSnackbarSuccess',
        {
          message: this.$i18n.t('link_copied'),
          duration: 6000,
        },
        { root: true }
      );
    });
  }
}
