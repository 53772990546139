import { Component, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import docExtraStatus, {
  OrganisationDocumentExtraStatusSetting,
  Value,
} from '@/shared/model/organisationDocumentExtraStatusSetting';
import orgDocProperty, { OrganisationDocumentProperty } from '@/shared/model/organisationDocumentProperty';
import EditDocumentStatusDialog from '@/views/settings/company/documents/edit-document-status/edit-document-status.vue';
import EditDocumentPropertyDialog from './edit-document-property-dialog/edit-document-property-dialog.vue';
import draggable from 'vuedraggable';
import { OrganisationDocumentListSetting } from '@/shared/model/organisationDocumentListSetting';
import DocumentNameparser from './document-nameparser/document-nameparser.vue';

const name = 'documents-settings-view';
const logger = new Logger(name);
const documentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');
const organisationDocumentListSettingModule = namespace('organisationDocumentListSetting');
const documentPropertyModule = namespace('organisationDocumentProperty');

@Component({
  name: name,
  components: { EditDocumentStatusDialog, EditDocumentPropertyDialog, draggable, DocumentNameparser },
})
export default class DocumentSettingsView extends Vue {
  @documentExtraStatusSettingModule.Action('getOrganisationDocumentExtraStatusSettings')
  private actionGetOrganisationDocumentExtraStatusSettings!: any;
  @documentExtraStatusSettingModule.Action('updateOrganisationDocumentExtraStatusSetting')
  private actionUpdateOrganisationDocumentExtraStatusSetting!: any;
  @documentExtraStatusSettingModule.Getter('getOrganisationDocumentExtraStatusSettings')
  private getOrganisationDocumentExtraStatusSettings!: any;

  get documentExtraStatuses() {
    return this.getOrganisationDocumentExtraStatusSettings.items;
  }

  documentExtraStatusesOld: any[] = [];

  async mounted() {
    logger.log('mounted');
    await this.loadInitData();
  }

  private async loadInitData() {
    logger.log('start loading');
    await this.actionGetOrganisationDocumentExtraStatusSettings();
    this.documentExtraStatusesOld = this.documentExtraStatuses;
    await this.actionGetOrganisationDocumentPropertys();
    await this.actionGetOrganisationDocumentListSettings();
  }

  //#region EditDocumentExtraStatusDialog logic
  dialogAddEdit = {
    show: false,
    model: { ...docExtraStatus.parse({}) },
  };

  async dialogAddEditOnClose(folder: any) {
    this.dialogAddEdit.show = false;
  }

  async dialogAddEditOnUpdate(item: any) {
    const result = await this.actionUpdateOrganisationDocumentExtraStatusSetting(item)
      .then(() => {
        this.dialogAddEdit.show = false;
        this.actionGetOrganisationDocumentExtraStatusSettings();
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private async openDocumentExtraStatusDialog(status: any) {
    this.dialogAddEdit.model = { ...status };
    this.dialogAddEdit.show = true;
  }
  //#endregion

  @documentPropertyModule.Action('getOrganisationDocumentPropertys')
  private actionGetOrganisationDocumentPropertys!: any;
  @documentPropertyModule.Action('updateOrganisationDocumentProperty')
  private actionUpdateOrganisationDocumentProperty!: any;
  @documentPropertyModule.Action('moveOrganisationDocumentProperty')
  private actionMoveOrganisationDocumentProperty!: any;
  @documentPropertyModule.Getter('getOrganisationDocumentPropertys')
  private getOrganisationDocumentPropertys!: any;

  //#region EditDocumentPropertyDialog logic
  docPropertyDialog = {
    show: false,
    model: { ...orgDocProperty.parse({}) },
  };

  async docPropertyDialogOnUpdate(item: any, isFromEdit: boolean) {
    if (isFromEdit) {
      const favoritesCount = this.getOrganisationDocumentPropertys?.items.filter((x: any) => x.favorite).length;
      if (favoritesCount >= 3 && item.favorite) {
        this.$message.open(`${this.$t('warning_morethan3_title')}`, `${this.$t('warning__morethan3_text')}`, {
          cancelColor: 'error',
          okColor: 'grey',
          okText: this.$i18n.t('close_btn'),
        });
        this.docPropertyDialog.show = false;
        return;
      }
    }

    const result = await this.actionUpdateOrganisationDocumentProperty(item)
      .then(() => {
        this.docPropertyDialog.show = false;
        this.actionGetOrganisationDocumentPropertys();
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private async openDocumentPropertyDialog(property: any) {
    this.docPropertyDialog.model = { ...property };
    this.docPropertyDialog.show = true;
  }

  async docPropertyDialogOnClose() {
    this.docPropertyDialog.show = false;
  }

  //#endregion

  //#region `draggable` logic
  options = {};
  get dragOptions() {
    return {
      animation: 220,
      group: 'description',
      disabled: false,
      ghostClass: 'ghost',
    };
  }
  onChangeDocumentProperty_SortOrder(payload: {
    moved: { element: OrganisationDocumentProperty; newIndex: number; oldIndex: number };
  }) {
    const newIndex =
      payload.moved.newIndex > payload.moved.oldIndex
        ? this.getOrganisationDocumentPropertys.items[payload.moved.newIndex - 1].sortOrder
        : this.getOrganisationDocumentPropertys.items[payload.moved.newIndex + 1].sortOrder;
    const oldIndex = this.getOrganisationDocumentPropertys.items[payload.moved.newIndex].sortOrder;
    this.actionMoveOrganisationDocumentProperty({
      element: payload.moved.element,
      newIndex: newIndex,
      oldIndex: oldIndex,
    }).catch(() => {
      this.actionGetOrganisationDocumentPropertys();
    });
  }

  onChangeDocumentProperty_Enabled(property: OrganisationDocumentProperty) {
    this.docPropertyDialogOnUpdate(property, false);
  }
  //#endregion

  private formatActiveItem(isActive: boolean) {
    return isActive ? `${this.$t('item_enabled')}` : `${this.$t('item_disabled')}`;
  }

  @organisationDocumentListSettingModule.Action('getOrganisationDocumentListSettings')
  private actionGetOrganisationDocumentListSettings!: any;
  @organisationDocumentListSettingModule.Action('updateOrganisationDocumentListSetting')
  private actionUpdateOrganisationDocumentListSetting!: any;
  @organisationDocumentListSettingModule.Getter('getOrganisationDocumentListSettings')
  private getOrganisationDocumentListSettings!: any;
  @organisationDocumentListSettingModule.Action('moveOrganisationDocumentListSetting')
  private actionMoveOrganisationDocumentListSetting!: any;

  //#region ListView `draggable` logic
  optionsLs = {};
  get dragOptionsLs() {
    return {
      animation: 220,
      group: 'description',
      disabled: false,
      ghostClass: 'ghost',
    };
  }
  onChangeDocumentList_SortOrder(payload: {
    moved: { element: OrganisationDocumentListSetting; newIndex: number; oldIndex: number };
  }) {
    const newIndex =
      payload.moved.newIndex > payload.moved.oldIndex
        ? this.getOrganisationDocumentListSettings.items[payload.moved.newIndex - 1].sortOrder
        : this.getOrganisationDocumentListSettings.items[payload.moved.newIndex + 1].sortOrder;
    const oldIndex = this.getOrganisationDocumentListSettings.items[payload.moved.newIndex].sortOrder;
    this.actionMoveOrganisationDocumentListSetting({
      element: payload.moved.element,
      newIndex: newIndex,
      oldIndex: oldIndex,
    }).catch(() => {
      this.actionGetOrganisationDocumentListSettings();
    });
  }

  async docListDialogOnUpdate(item: any, isFromEdit: boolean) {
    const result = await this.actionUpdateOrganisationDocumentListSetting(item)
      .then(() => {
        this.actionGetOrganisationDocumentListSettings();
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  onChangeDocumentList_Enabled(property: OrganisationDocumentProperty) {
    this.docListDialogOnUpdate(property, false);
  }
  //#endregion
}
